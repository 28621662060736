/*
Author: Yunus
*/
var cbt;

var cbt_carousel_int = jQuery.fn.carousel.noConflict();
jQuery.fn.cbt_carousel = cbt_carousel_int;
jQuery.fn.carousel = cbt_carousel_int;

// as the page loads, call these scripts
jQuery(document).ready(function($) {
    // ------------------------------------------------------
    //  responsive check
    // ------------------------------------------------------
    var is_mobile = false;
    function cbt_responsive_check() {
        if ($(window).width() < 830) {
            is_mobile = true;
        } else {
            is_mobile = false;
        }
    }
    cbt_responsive_check();
    $(window).resize(function() {
        cbt_responsive_check();
    });

    // rtl check
    var is_rtl = false;
    if ($('body').hasClass('rtl'))
        is_rtl = true;

    // Fit vids to size videos responsively
    $(".post").fitVids();

    // Assign the HTML, Body as a variable...
    var $viewport = $('html, body');

    $('input, textarea').placeholder(); // IE placeholder fix

    // if($viewport.hasClass('touch'))
    // {
    //     // no sticky on touch devices for performance reasons
    // }
    // else
    // {
        $(".do-sticky").each(function () {
            var elm = this;
            $(elm).sticky({topSpacing: 0});

            if( $(elm).hasClass('do-sticky-fixed') )
                return;

            var sticky_started = false;
            $(elm).on('sticky-start', function() {
                sticky_started = true;
            });
            $(elm).on('sticky-end', function() {
                sticky_started = false;
                $(this).removeClass('sticky-down stop-sticky');
                cbt.no_slide_area_adjust();
            });

            var cbt_lastScrollTop = 0;
            var min_for_sticky = 0;

            $(window).scroll(function(event) {
                if (is_mobile)
                    min_for_sticky = 150;
                else
                    min_for_sticky = 0;

                var cbt_st = $(this).scrollTop();
                if (cbt_st > min_for_sticky && cbt_st > cbt_lastScrollTop) {
                    $(elm).addClass('stop-sticky');
                } else {
                    $(elm).addClass('sticky-down').removeClass('stop-sticky');
                }
                cbt_lastScrollTop = cbt_st;
            });

        });

    // }


    var window_height,
        body_height,
        margin;
    function resize_boxed_layout() {
        $('#content').css('margin-bottom', 0);

        window_height = parseFloat($(window).height());
        body_height = parseFloat($('body').height());
        margin = 0;

        if ($('body').hasClass('admin-bar'))
            window_height -= 32;

        margin = window_height - body_height;
        if (margin < 0)
            margin = 0;

        $('#content').css('margin-bottom', margin);
    }

    if ($('body').hasClass('boxed_layout')) {
        resize_boxed_layout();

        $(window).resize(function() {
            waitForFinalEvent(function() {
                resize_boxed_layout();
            }, 500, "786");
        });
    }

    var $window = $(window); //You forgot this line in the above example

    var $bgobj = $('#slider_area.no_slide .nav_bg'); // assigning the object

    var yPos,
        coords;
    $(window).scroll(function() {
        yPos = -($window.scrollTop() / $bgobj.data('speed'));

        // Put together our final background position
        coords = '50% ' + yPos + 'px';

        // Move the background
        $bgobj.css({backgroundPosition: coords});
    });

    if ($('#fcw_map').length != 0) {
        widget_contact_map();
    }

    $(window).scroll(function() {
        waitForFinalEvent(function() {

            if ($(window).scrollTop() > 50) {
                $('#back-to-top').fadeIn();
            } else {
                $('#back-to-top').fadeOut();
            }

        }, 500, "backtotop");

    });

    // scroll body to 0px on click
    $('#back-to-top').click(function() {
        $('#back-to-top').tooltip('hide');
        $viewport.animate({
            scrollTop: 0
        }, 800);
        return false;
    });

    $('#back-to-top').tooltip('show');

    // Stop the animation if the user scrolls. Defaults on .stop() should be fine
    $viewport.bind("scroll mousedown DOMMouseScroll mousewheel keyup", function(e) {
        if (e.which > 0 || e.type === "mousedown" || e.type === "mousewheel") {
            $viewport.stop();
        }
    });

    function widget_contact_map() {
        var lat = $('#fcw_map').data('lat');
        var lng = $('#fcw_map').data('lng');
        var zoom = $('#fcw_map').data('zoom');
        var latLng = new google.maps.LatLng(lat, lng);

        //options
        var mapOptions = {
            center: latLng,
            zoom: zoom,
            mapTypeControlOptions: {
                mapTypeIds: [google.maps.MapTypeId.ROADMAP, 'map_style']
            },
            scrollwheel: false,
            panControl: false,
            zoomControl: false,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false

        };

        var map = new google.maps.Map(document.getElementById('fcw_map'), mapOptions);

        var marker = new google.maps.Marker({position: latLng, map: map});

        google.maps.event.addListener(map, 'click', function(event) {
            this.setOptions({zoomControl: true});
        });

        $('body').on('mousedown', function(event) {
            var clickedInsideMap = $(event.target).parents('#fcw_map').length > 0;

            if (!clickedInsideMap) {
                map.setOptions({zoomControl: false});
            }
        });
    }

    cbt = {

        navigation_mega: function() {
            if (is_mobile)
                return false;

            var nav_width,
                nav_offset;
            $('nav:not(.cbb-nav-vertical) ul.nav').each(function() {
                nav_width = $(this).width();
                nav_offset = $(this).offset().left;

                $(this).find('> li.menu-item-has-children > ul.dropdown-menu').each(function() {
                    if ($(this).find('> li').size() > 5) {
                        if (is_rtl)
                            $(this).addClass('dropdown-mega').css({
                                'width': nav_width,
                                'right': (nav_offset - $(this).parent().offset().left)
                            });
                        else
                            $(this).addClass('dropdown-mega').css({
                                'width': nav_width,
                                'left': (nav_offset - $(this).parent().offset().left)
                            });
                        }

                    return;
                });
            });
        },

        // ------------------------------------------------------
        //  Navigation dropdown adjustment. e.g when dropdown is going to be outside the window make it open on the right side instead
        // ------------------------------------------------------
        navigation: function() {
            var elm_width,
                distance,
                viewport;
            $('ul.nav > li.menu-item-has-children li.dropdown').mouseover(function() {
                if (is_mobile)
                    return;

                if ($('body').hasClass('boxed_layout')) {
                    viewport = $('#boxed');
                } else {
                    viewport = $(window);
                }

                elm_width = $(this).width();
                distance = viewport.width() - ($(this).offset().left + elm_width);

                if (distance < (elm_width + 20)) {
                    $(this).addClass('dropdown-menu-right');
                } else {
                    $(this).removeClass('dropdown-menu-right');
                }

                return false;
            });

            $('ul.nav li.menu-item-has-children, ul.nav li.menu-item-st-woo-cart').on('mouseenter', function() {
                if (is_mobile)
                    return;

                $(this).children('.dropdown-menu').stop().css('height','auto').slideDown(300);
            }).on('mouseleave', function() {
                if (is_mobile)
                    return;

                $(this).children('.dropdown-menu').stop().slideUp(200);
            })

            var target;
            $('.navbar-toggle').click(function() {
                if (!is_mobile)
                    return;
                target = $(this).data('target');

                if ($("html").hasClass('openNav')) {
                    $("html").removeClass('openNav');
                    //cbt.enablePageScroll();
                } else {
                    $('.mobile-nav-holder').html('');
                    $(target).clone(true).appendTo('.mobile-nav-holder');

                    waitForFinalEvent(function() {
                        $("html").addClass("openNav");
                        //cbt.disablePageScroll();

                    }, 50, "add-opennav");
                }

                return false;
            });

            // ------------------------------------------------------
            //  Mobile scroll bug fix
            // ------------------------------------------------------
            function fixSafariScrolling(event) {
                event.target.style.overflowY = 'hidden';
                setTimeout(function() {
                    event.target.style.overflowY = 'auto';
                });
            }

            if (is_mobile) {
                $('.navbar-collapse.openNav').bind('webkitAnimationEnd', fixSafariScrolling);
            }

            // ------------------------------------------------------
            //  handle section hash links
            // ------------------------------------------------------
            $("a[href*='#section-']").click(function(e) {

                var element = $(this).attr('href');
                element = /(#.*)$/.exec(element);
                if (!element || !element[1])
                    return true;

                element = element[1];

                if ($(element).length == 0)
                    return;

                e.preventDefault();

                $viewport.animate({
                    scrollTop: $(element).offset().top
                }, 1500);

                if (is_mobile) {
                    $("html").removeClass("openNav").removeClass("pre-openNav");
                    $('.navbar-collapse.openNav').removeClass("openNav");
                }

                $('#header .navbar-collapse').removeClass('in')
            });

            // ------------------------------------------------------
            //  Multi level menu dropdown for deeper levels in responsive view
            // ------------------------------------------------------
            $('.nav-dropdown-toggle-deeper').click(function() {
                $(this).parent().find('> ul.dropdown-menu').toggle();
                return false;
            });
        },

        layout_adjustment: function() {
            cbt.navigation_mega();
            cbt.no_slide_area_adjust();
            $(window).trigger('st_layout_adjustment');
        },

        no_slide_height_zone: null,
        no_slide_area_height: 163,
        no_slide_area_adjust: function() {
            //|| $('.no_slide_height_zone').parent().css('position')=='fixed'
            if ($('.no_slide_height_zone').length < 1)
                return;

            cbt.no_slide_height_zone = $('.no_slide_height_zone');

            cbt.no_slide_area_height = $('.no_slide').data('min-height');

            cbt.no_slide_area_height += $('.no_slide .nav_bg > header > div:first-child').outerHeight();
            if (cbt.no_slide_area_height < 30) {
                cbt.no_slide_area_height += $('.no_slide .nav_bg > header > div:last-child').outerHeight();
            }

            if (cbt.no_slide_height_zone.css('position') == 'absolute') {
                cbt.no_slide_area_height += cbt.no_slide_height_zone.outerHeight();
            }

            $('.no_slide .nav_bg').css('min-height', cbt.no_slide_area_height + 'px');
        },

        portfolio: function() {
            $('div.cbt-portfolio-instance').each(function() {
                var isotope = $('.cbt-portfolio', $(this));
                isotope.isotope({
                    // options
                    itemSelector: '.isotope-item',
                    layoutMode: 'fitRows'
                });
                jQuery(window).load(function() {
                    isotope.isotope("layout");
                });

                // filter items on button click
                $('.cbt-portfolio-filters', $(this)).on('click', 'button', function() {
                    var filterValue = $(this).attr('data-filter');
                    isotope.isotope({filter: filterValue});
                    return false;
                });
            });
        },

        testimonials: function() {
            var auto_scroll,
                interval,
                carousel_id;
            $('div.cbt-testimonials-instance').each(function() {
                var carousel_id = $(this).data('carousel-id');

                auto_scroll = $(this).data('auto_scroll');
                if (auto_scroll != 'no')
                    interval = parseInt($(this).data('interval'));
                else
                    interval = false;

                $(carousel_id + ' .testimonials-carousel').cbt_carousel({pause: "hover", interval: interval});

                if ($('div.cbt-testimonials-grid', $(this)).length != 0) {
                    var isotope = $('div.cbt-testimonials-grid', $(this));
                    isotope.isotope({
                        // options
                        itemSelector: '.item',
                        layoutMode: 'fitRows'
                    });
                    jQuery(window).load(function() {
                        isotope.isotope("layout");
                    });
                }
            });

            var carousel_id;
            $('.testimonials-carousel .right').click(function() {
                var carousel_id = $(this).attr('href');
                $(carousel_id + '.testimonials-carousel').cbt_carousel('next');
            });

            $('.testimonials-carousel .left').click(function() {
                var carousel_id = $(this).attr('href');
                $(carousel_id + '.testimonials-carousel').cbt_carousel('prev');
            });
        },

        notification: function (msg, status, timeout) {
            if(! status || status=='error') status = 'danger';
            if(! msg) return false;

            $('body').append('<div class="cbt-notification alert alert-'+status+'" role="alert"><button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>'+msg+'</div>');
            $(".cbt-notification").slideDown(500);

            if(timeout !== false)
            {
                if(! timeout) timeout = 4000;
                window.setTimeout(function() {
                    $(".cbt-notification").slideUp(500, function(){
                        $(this).remove(); 
                    });
                }, timeout);
            }
        },

        // Disable page scroll
        tempScrollTop: 0,
        scrollDisabled: false,
        disablePageScroll: function() {
            // $('body').addClass('cbt-noscroll');
            cbt.scrollDisabled = true;
            cbt.tempScrollTop = $(window).scrollTop();
            $('body').width($('body').width());
            $('body').height($(window).height());
            $('body').css('overflow', 'hidden');

            // fix full width row gap (WIP)
            // $('.full_wide_content').width( $('html').width() );
        },

        // Enable page scroll
        enablePageScroll: function() {
            // $('body').removeClass('cbt-noscroll');
            cbt.scrollDisabled = false;
            $('body').css({'width': 'auto', 'height': 'auto', 'overflow': 'auto'});
            $(window).scrollTop(cbt.tempScrollTop);
        }
    }

    cbt.layout_adjustment();
    cbt.navigation();

    $(window).resize(function() {
        waitForFinalEvent(function() {
            cbt.layout_adjustment();
        }, 500, "cbt_layout_adjust");
    });

    // ------------------------------------------------------
    //  Wait for Visual composer and ultimate addon to load and run its initial stuff
    //  like rows design etc AND THEN do our vc elements stuff
    // ------------------------------------------------------
    var after_vc_load = setInterval(function() {
        if ($('div.cbt-portfolio-instance').length != 0)
            cbt.portfolio();

        if ($('div.cbt-testimonials-instance').length != 0)
            cbt.testimonials();

        // native js composer functions
        //window.vc_js();

        window.clearInterval(after_vc_load)
    }, 1000); //wait 1 seconds, just enough for vc to do all its thing

});/* end of as page load scripts */
